<template>
    <div id="pageList" class="ChannelList">
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">渠道名称：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.name = validForbid(e)" placeholder="请输入渠道名称进行模糊匹配查询..." v-model="pageList.queryParam.name" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">上级机构：</el-col>
                    <el-col :span="9">
                        <el-cascader :ref="el=>refMap.set('corpId',el)" v-model="pageList.queryParam.corpId" placeholder="请选择上级机构" style="width:100%" :options="cascaderData({comboId:'corpId'})"/>
                    </el-col>
                </el-row>

                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">渠道类型：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="pageList.queryParam.type" placeholder="请选择渠道类型" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系人：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.contacts = validForbid(e)" placeholder="请输入联系人进行模糊匹配查询..." v-model="pageList.queryParam.contacts" clearable/>
                    </el-col>

                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">联系电话：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.telephone = validForbid(e)" placeholder="请输入联系电话进行模糊匹配查询..." v-model="pageList.queryParam.telephone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">联系地址：</el-col>
                    <el-col :span="9">
                        <el-input @input="e => pageList.queryParam.address = validForbid(e)" placeholder="请输入联系地址进行模糊匹配查询..." v-model="pageList.queryParam.address" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">是否停用：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('flag',el)" v-model="pageList.queryParam.flag" placeholder="请选择是否停用" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'flag'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                    <el-col :span="3" class="myColTitle">审核状态：</el-col>
                    <el-col :span="9">
                        <el-select :ref="el=>refMap.set('status',el)" v-model="pageList.queryParam.status" placeholder="请选择审核状态" clearable style="width:100%">
                            <el-option v-for="item in comboSelect({comboId:'status'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-col>
                </el-row>
            </template>

            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="渠道名称"/>
                <el-table-column prop="PARENTCORPNAME" label="上级机构"/>
                <el-table-column prop="F_TYPE" label="渠道类型"/>
                <el-table-column prop="F_CONTACTS" label="联系人"/>
                <el-table-column prop="F_TELEPHONE" label="联系电话"/>
                <el-table-column prop="F_ADDRESS" label="联系地址"/>
                <el-table-column prop="F_CODE" label="登录账号"/>
                <el-table-column prop="F_FLAG" label="是否停用"
                                 :filters="[{value:'启用',text:'启用'},{value:'禁用',text:'禁用'}]"
                                 :filter-method="(value,row)=>{return filterData(value,row,'F_FLAG')}"/>
                <el-table-column prop="F_STATUS" label="审核状态"
                                 :filters="[{value:'待审核',text:'待审核'},{value:'通过',text:'通过'},{value:'拒绝',text:'拒绝'}]"
                                 :filter-method="(value,row)=>{return filterData(value,row,'F_STATUS')}"/>
                <el-table-column prop="F_TIME" label="创建时间"/>

            </template>
        </ListEngine>
    </div>
</template>

<script>
    import ChannelCard from './ChannelCard';
    import {
        ref,
        reactive,
        toRefs,
        computed,
        defineComponent,
        onMounted,
        getCurrentInstance,
        watch,
        provide,
        nextTick
    } from 'vue';
    export default defineComponent ({
        name: "ChannelList",
        setup(){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                corpTreeData: [],
                provinceData: [],
                hasOperatePermission:true,
                pageList: {
                    queryParam: {
                        corpId:"",
                        provinceId:"",
                        type:"",
                        flag:"",
                        status:"",
                        name:"",
                        telephone:"",
                        contacts:"",
                        address:""
                    },
                    modelComp: ChannelCard,
                    modelMethod: "/channel/pageData"
               }
            })
            onMounted(async ()=>{
                dataObj.corpTreeData=await utils.$$api.corpTreeData();
                dataObj.provinceData= await utils.$$api.getProvinces({});//省
                nextTick(async()=>{
                    dataObj.hasOperatePermission=dataObj.pageListRef.hasOperatePermission();
                })
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    if(params && 'provinceId'==params.comboId){
                        return dataObj.provinceData;
                    }
                    if(params && 'type'==params.comboId){
                        return [{value:0,label:'企业'},{value:1,label:'个人'}]
                    }
                    if(params && 'flag'==params.comboId){
                        return [{value:'0',label:'启用'},{value:'1',label:'禁用'}]
                    }
                    if(params && 'status'==params.comboId){
                        return [{value:'0',label:'待审核'},{value:'1',label:'通过'},{value:'2',label:'拒绝'}]
                    }
                }
            })
            const cascaderData=computed(()=>{
                return (params) => {
                    if(params && 'corpId'==params.comboId){
                        return dataObj.corpTreeData;
                    }
                }
            })

            const filterData=(value, row,field)=>{
                return row[field] == value;
            }
            return{
                ...toRefs(dataObj),comboSelect,cascaderData,filterData
            }
        }
    });
</script>

<style scoped>
    .ChannelList{
        width: 100%;
    }
</style>